import { Route } from "react-router-dom";
import NewsLetter from "./views/NewsLetter";
import ArticleView from "./views/ArticleView";

const NewsLetterRoutes = [
    <Route path='/news-letter' key='news-letter' element={<NewsLetter />}>
        <Route path="/news-letter/latest" key="news-letter-latest" element={<ArticleView latest={true} />} />
        <Route path="/news-letter/:id" key="news-letter-id" element={<ArticleView />} />
    </Route>
]

export default NewsLetterRoutes;
