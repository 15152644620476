import { Box, Button, Card, CardActions, CardContent, Container, Grid, Typography } from "@mui/material"
import { MapContainer, TileLayer } from "react-leaflet"
import L, { Map } from 'leaflet'
import "leaflet/dist/leaflet.css";
import { useGetArticlesQuery } from "../api/landingApi";
import { useCallback, useMemo, useState } from "react";
//@ts-ignore
import wkt from 'wkt'

const MapArticle = ({ article, map }: { article: any, map: Map }) => {
    const coordinates = wkt.parse(article.location).coordinates
    const moveTo = useCallback(() => map.flyTo([coordinates[1], coordinates[0]], article.zoom), [map, article, coordinates])
    const localeDate = new Date(article.date).toLocaleString('es-CL')
    return <Card sx={{ height: 1 }} >
        <CardContent>
            <Typography gutterBottom variant="h5" component="div">
                {article.title}
            </Typography>
            <Typography gutterBottom variant="subtitle2" component="div">
                {localeDate}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                {article.content}
            </Typography>
        </CardContent>
        <CardActions>
            <Button size="large" variant="contained" onClick={moveTo}>Mostrar</Button>
        </CardActions>
    </Card>
}

const MapArticles = ({ map }: { map: Map }) => {
    const { data, isLoading } = useGetArticlesQuery({})

    return isLoading
        ? null
        : <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent="center" alignItems="stretch">
            {data && data.map((article: any) => (
                <Grid item xs={4} sm={4} md={4} key={article.title}>
                    <MapArticle article={article} map={map} key={article.title} />
                </Grid>
            ))}
        </Grid>
}

const ExternalStateMap = () => {
    const [map, setMap] = useState<Map | null>(null)

    const displayMap = useMemo(
        () => (
            <MapContainer center={{ lat: -31, lng: -71 }} zoom={7} crs={L.CRS.EPSG3857} ref={setMap}>
                <TileLayer
                    url="https://sh.dataspace.copernicus.eu/ogc/wmts/e88b26dd-90ae-42e1-a6e0-645c9e7a4dca?REQUEST=GetTile&SERVICE=WMTS&TILEMATRIXSET=PopularWebMercator256&TILEMATRIX={z}&TILECOL={x}&TILEROW={y}&LAYER=TRUE-COLOR&FORMAT=image/jpeg"
                    minZoom={7}
                    maxZoom={25}
                />
                <TileLayer
                    url="https://geo-api.rovisen.com:8443/geoserver/gwc/service/wmts/rest/rovisen:regions/line/EPSG:900913/EPSG:900913:{z}/{y}/{x}?format=image/png"
                    maxZoom={25}
                    bounds={[[-88.99999937941338, -109.99897038543271], [-17.175687067010177, -65.55623172256713]]}
                />
            </MapContainer>
        ), []
    )

    return (
        <>
            {map ? <MapArticles map={map} /> : null}
            <Box sx={{ height: '50vh', paddingY: 5, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box sx={{ width: "60%", height: 1 }}>
                    {displayMap}
                </Box>
            </Box>
        </>
    )
}

const MapSection = () => {

    return <Container id="news">
        <Box sx={{ paddingX: 4, paddingTop: 5, marginTop: 5, textAlign: 'center' }}>
            <Typography variant="h2" fontWeight="fontWeightBold">Mapa base</Typography>
        </Box>
        <Typography variant="h6" sx={{ marginTop: 5 }}>
            Ponemos a libre disposición<sup>1</sup> nuestro servicio WMS (<i>Web Mapping Service</i>) que provee un
            mapa base de Chile en tiempo real. Este utiliza imágenes satelitales Sentinel 2 y nos permite observar
            fenómenos y sucesos con una resolución temporal de 5 días<sup>2</sup>.
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: 5 }}>
            <Button variant="contained" size="large" onClick={() => window.open("https://outlook.office365.com/owa/calendar/rovisen@rovisen.com/bookings/")}>
                Solicita Acceso
            </Button>
        </Box>
        <ExternalStateMap />
        <Typography>
            <small><sup>1</sup>Este servicio se encuentra en versión preliminar y es de libre acceso durante tiempo
                limitado.</small>
        </Typography>
        <Typography>
            <small><sup>2</sup>La frecuencia de actualización está supeditada a la cantidad de nubosidad
                presente.</small>
        </Typography>
    </Container>
}

export default MapSection