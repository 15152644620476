import DOMPurify from 'dompurify';

const SanitizedHTML = ({ htmlContent }: { htmlContent: string }) => {
    const sanitizedContent = DOMPurify.sanitize(htmlContent);

    return (
            <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
    );
};

export default SanitizedHTML;
