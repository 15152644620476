import { Card, CardActionArea, CardContent, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
// import SanitizedHTML from "../utils/SanitizedHTML";

interface Article {
    id: number;
    title: string;
    content: string;
    author: string;
    date: string;
}

interface AdminProps {
    admin?: boolean;
}

function ArticleCard({ id, title, content, author, date, admin }: Article & AdminProps) {
    const navigate = useNavigate();

    const handleCardClick = () => {
        admin ? navigate(`/admin/edit-newsletter/${id}`) : navigate(`/news-letter/${id}`);
    }

    return (
        <Card style={{
            height: '90px',
            overflow: 'hidden',
        }}>
            <CardActionArea onClick={handleCardClick}>
                <CardContent style={{ overflow: 'hidden' }}> {/* Apply overflow: hidden to limit content */}
                    <Typography className="nunito-title" variant="h6" component="h2" sx={{ fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {title}
                    </Typography>
                    {/* <Typography className="nunito-content" variant="body1" component="p" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        <SanitizedHTML htmlContent={content} />
                    </Typography> */}
                    <Typography className="nunito-info" color="text.secondary" variant='body2' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {author} - {date}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default ArticleCard;
export type { Article };
