import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import NavBar from "../../app/views/layout/NavBar";
import santiago_img from '../../landingPage/static/assets/imgs/santiagocity.jpg';
import ArticleList from "../components/ArticleList";
import '../static/css/newsletter.scss';
import { useGetNewslettersQuery } from "../api/newsLetterApi";


function NewsLetter() {
    const location = useLocation();
    const navigate = useNavigate();
    const [isArticleOpen, setIsArticleOpen] = useState(false);
    const isMdScreen = useMediaQuery('(min-width:900px)');
    useGetNewslettersQuery({});

    useEffect(() => {
        setIsArticleOpen(location.pathname.includes('/news-letter/'));
    }
    , [location.pathname, navigate]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
                boxSizing: 'border-box'
            }}
            style={{
                background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.5) 75%, #000 100%), url(${santiago_img})`,
                backgroundPosition: "center",
                backgroundSize: "cover"
            }}
        >
            <NavBar />
            <Typography className="chomsky-font" variant="h3" textAlign={'center'} padding={1} sx={{ bgcolor: grey[500] }}>
                New York 25
            </Typography>
            <Grid container flexGrow={1}>
                <Grid item xs={isArticleOpen ? 3: true} sx={{ display: (!isArticleOpen || isMdScreen) ? 'flex': 'none', flexDirection: 'column', alignItems: 'center' }}>
                    <ArticleList />
                </Grid>
                <Grid item xs={isArticleOpen} marginTop={1} marginBottom={1} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    {/* outlet renders <ArticleView /> */}
                    <Outlet />
                </Grid>

            </Grid>
        </Box>
    )
}

export default NewsLetter;
