import CloseIcon from '@mui/icons-material/Close';
import { Box, Card, CardContent, Divider, IconButton, Typography } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from '../../app/state/store';
import { selectArticleById, selectLatestArticle } from '../state/newsLetterSlice';
import { formatDate } from '../utils/utils';
import SanitizedHTML from '../utils/SanitizedHTML';

function ArticleView({latest = false}) {
    const params = useParams<{id: string}>();
    const navigate = useNavigate();
    const article = useSelector((state: RootState) => latest ? selectLatestArticle(state) : selectArticleById(state, Number(params.id)));

    const handleClose = () => {
        navigate('/news-letter');
    }

    return (
            <Card sx={{ width: 4/5, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                <CardContent style={{ position: 'relative', flexGrow: '1', display: 'flex', flexDirection: 'column' }}>
                    <IconButton
                        onClick={handleClose}
                        style={{ position: 'absolute', top: 0, right: 0 }}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                    {article && (
                        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                            <Typography className="nunito-title" variant="h5" component="h2" sx={{ fontWeight: 'bold' }}>
                                {article.title}
                            </Typography>
                            <Typography className="nunito-info" color="text.secondary" variant='body2'>
                                {article.author} - {formatDate(article.date)}
                            </Typography>
                            <Divider />
                            <Box sx={{
                                flexGrow: 1,
                                overflow: 'auto',
                                height: 0
                            }}>
                                <SanitizedHTML htmlContent={article.content} />
                            </Box>
                        </Box>
                    )}
                </CardContent>
            </Card>
    );
}

export default ArticleView;
