import { configureStore } from "@reduxjs/toolkit";
import { rovisenApi } from "../../app/api/apiBase";
import newsLetterReducer from "../../newsLetter/state/newsLetterSlice";
import authReducer from '../../authentication/state/authSlice';

const store = configureStore({
    reducer: {
        newsLetter: newsLetterReducer,
        auth: authReducer,
        [rovisenApi.reducerPath]: rovisenApi.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(rovisenApi.middleware)
})

export { store }
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
