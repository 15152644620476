import { createSlice } from "@reduxjs/toolkit";


interface AuthState {
    username: string | null;
    token: string | null;
}

const initialState: AuthState = {
    username: null,
    token: null,
};
const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        setUser: (state, action) => {
            state.username = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        logOut: (state) => {
            state.username = null;
            state.token = null;
        },
    }
});

export const { setUser, setToken, logOut } = authSlice.actions;

export default authSlice.reducer;
