import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { newsLetterApi } from "../api/newsLetterApi";

interface Article {
    id: number;
    title: string;
    content: string;
    author: string;
    date: string;
    type: string;
}

interface NewsLetterState {
    articles: Article[];
}

const initialState: NewsLetterState = {
    articles: []
};

export const newsLetterSlice = createSlice({
    name: 'newsLetter',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(newsLetterApi.endpoints.getNewsletters.matchFulfilled, (state, action: PayloadAction<Article[]>) => {
                state.articles = action.payload;
            })
    },
    selectors: {
        selectArticles: (state: NewsLetterState) => state.articles,
        selectLatestArticle: (state: NewsLetterState) => state.articles[0],
        selectArticleById: (state: NewsLetterState, id: number) => state.articles.find((article) => article.id === id)
    }
});

export const {
    selectArticles,
    selectArticleById,
    selectLatestArticle
} = newsLetterSlice.selectors;

export default newsLetterSlice.reducer;
