import { useDispatch } from "react-redux";
import { AppDispatch } from "../../app/state/store";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Cookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";
import { setToken, setUser } from "../state/authSlice";
import LogOut from "../views/Logout";
import { Box } from "@mui/material";
import packageJson from '../../../package.json';

function PrivateRoutes() {
    const dispatch = useDispatch<AppDispatch>();
    const location = useLocation();
    const cookies = new Cookies();
    const token = cookies.get('rovisen_auth_token');
    const decoded = token && jwtDecode(token);
    if (decoded && decoded.sub && token) {
        dispatch(setToken(token));
        dispatch(setUser(decoded.sub));
    }
    else {
        dispatch(setToken(''));
        dispatch(setUser(''));
    }

    // Redirect to external login page if not authenticated
    /* if (!token) {
        window.location.href = 'https://gis.rovisen.com/login';
        return null; // Prevent rendering any content before redirecting
    } */

    return (
        token ?
            (<Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100vh',
                }}
            >
                <div>{`v${packageJson.version}`}</div>
                <LogOut />
                <Outlet />
            </Box>
            ) :
            <Navigate to="/login" replace state={{ from: location }} />
    );
}

export default PrivateRoutes;
