import { Avatar, Box, CircularProgress, Container, Grid, Typography } from "@mui/material"
import { useGetTeamMembersQuery } from "../api/landingApi"

const TeamMember = ({ member }: { member: any }) => {
    return <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Avatar src={member.photo_url} alt={member.name} sx={{height: '25vh', width: '25vh'}}/>
        <Typography variant="h6" marginTop={5}>{member.name}</Typography>
        <Typography variant="body2" textAlign="center" color="primary.main">{member.job}</Typography>
        <Typography variant="body2" textAlign="center">{member.profesion}</Typography>
        <Typography variant="body2" textAlign="center">{member.description}</Typography>
        <Typography variant="body2" textAlign="center">{member.profession}</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            {member.linkedin && <Box component="a" href={member.linkedin}><Box component="i" className="fab fa-linkedin" sx={{ marginBottom: 2, paddingRight: 1 }} /></Box>}
            {member.github && <Box component="a" href={member.github}><Box component="i" className="fab fa-github" sx={{ marginBottom: 2 }} /></Box>}
        </Box>
    </Box>
}

const TeamMembers = () => {
    const { data, isLoading } = useGetTeamMembersQuery({})

    return <Box sx={{ width: 1, backgroundColor: '#E9ECEF' }} id="us">
        <Container sx={{ paddingTop: 5, marginTop: 5 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h2" fontWeight="fontWeightBold" textAlign="center" marginBottom={5}>Nosotros</Typography>
                {isLoading
                    ? <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                        <CircularProgress />
                    </Box>
                    : <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent="center">
                        {data && data.map((member) => (
                            <Grid item xs={4} sm={4} md={4} key={member.name}>
                                <TeamMember member={member} key={member.name} />
                            </Grid>
                        ))}
                    </Grid>}
            </Box>
        </Container>
    </Box>
}

export default TeamMembers