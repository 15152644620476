import { useDispatch } from "react-redux";
import { AppDispatch } from "../../app/state/store";
import { logOut } from "../state/authSlice";
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import { Cookies } from "react-cookie";

function LogOut() {
    const cookies = new Cookies();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const handleLogout = () => {
        cookies.remove('rovisen_auth_token');
        dispatch(logOut());
        navigate('/login');
    }
    return (
        <div>
            <Button variant="contained" onClick={() => {
                handleLogout();
            }}>
                <Typography textAlign="center">Log out</Typography>
            </Button>
        </div>
    );
}

export default LogOut;
