import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Container,
    CssBaseline,
    FormControlLabel,
    Grid,
    Link,
    TextField,
    Typography
} from "@mui/material";
import { loginApi } from "../api/authApi";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../app/state/store";
import { setToken, setUser } from "../state/authSlice";
import { useLocation, useNavigate } from "react-router-dom";
import AlertDialog from "../../common/components/AlertDialog";
import { useState } from "react";
import Cookies from 'universal-cookie';
import { jwtDecode } from "jwt-decode";
import packageJson from '../../../package.json';


function Login() {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const location = useLocation();
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        loginApi(data.get('username') as string, data.get('password') as string)
        .then(response => {
            const cookies = new Cookies();
            if (response.access_token){
                const decoded = jwtDecode(response.access_token);
                decoded && decoded.exp && cookies.set("rovisen_auth_token", response.access_token, {
                    path: '/',
                    expires: new Date(decoded.exp * 1000),
                    //domain: '.rovisen.com'
                })
                dispatch(setToken(response.access_token));
                dispatch(setUser(data.get('username') as string));
                location.state ? navigate(location.state.from) : navigate('/');
            }
            else {
                setAlertOpen(true);
                setAlertMessage(response.detail);
            }
        });

    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            >
                <Avatar src="logo_120.png" sx={{ m: 1 }} />
                <Typography component="h1" variant="h5">
                    Log in
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        autoComplete="username"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Log In
                    </Button>
                <Grid container>
                    <Grid item xs>
                        <Link href="#" variant="body2">
                            Forgot password?
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link href="#" variant="body2">
                            {"Don't have an account? Sign Up"}
                        </Link>
                    </Grid>
                </Grid>
                <div>{`v${packageJson.version}`}</div>
                </Box>
                <AlertDialog errorAlertOpen={alertOpen} setErrorAlertOpen={setAlertOpen} message={alertMessage} />
            </Box>
        </Container>
    );
}

export default Login;
