import { useEffect, useState } from "react";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "draft-js/dist/Draft.css";
import draftToHtml from "draftjs-to-html";
import htmltoDraft from "html-to-draftjs";
import "../static/editor.css";
import { Box, Card, CardContent, TextField, Typography } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useDeleteNewsLetterMutation, useLazyGetArticleByIdQuery, useSaveNewsLetterMutation, useUpdateArticleMutation } from "../../newsLetter/api/newsLetterApi";
import { useParams } from "react-router-dom";
import SanitizedHTML from "../../newsLetter/utils/SanitizedHTML";
import { LoadingButton } from "@mui/lab";
import { BaseAlertDialog } from "../../common/components/AlertDialog";
import '../../newsLetter/static/css/newsletter.scss';
import { useSelector } from "react-redux";
import { RootState } from "../../app/state/store";


const MyEditor = () => {
    const token = useSelector((state: RootState) => state.auth.token);

    const defaultFormValues = {
        title: '',
        author: '',
        date: new Date(),
        content: ''
    };

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('success');

    const params = useParams<{id: string}>();
    const editing = params.id ? true : false;
    const [getArticleTrigger, result] = useLazyGetArticleByIdQuery();

    const [formValues, setFormValues] = useState(defaultFormValues);
    const handleFormChange = (e: any) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        if (editing) {
            getArticleTrigger(params.id);
        }
    }, [params.id, editing, getArticleTrigger]);

    useEffect(() => {
        if (result.data) {
            const { title, author, date, content } = result.data;
            setFormValues({ title, author, date, content });
            const contentBlock = htmltoDraft(content);
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState);
        }
    }, [result.data]);

    const [saveArticleTrigger, saveResult] = useSaveNewsLetterMutation();
    const [updateArticleTrigger, updateResult] = useUpdateArticleMutation();
    const [deleteArticleTrigger, deleteResult] = useDeleteNewsLetterMutation();

    useEffect(() => {
        if (saveResult.isLoading || updateResult.isLoading || deleteResult.isLoading) {
            setAlertOpen(true);
        }
    }, [saveResult.isLoading, updateResult.isLoading, deleteResult.isLoading]);

    const handleFormSubmit = (e: any) => {
        e.preventDefault();
        const data = {
            ...formValues,
            date: dayjs(formValues.date).format('DD/MM/YYYY'),
            is_published: true
        };
        editing ? updateArticleTrigger({id: params.id, ...data, token: token}) : saveArticleTrigger({...data, token: token});
    };

    const handleAlert = (action: string, success: boolean, error: boolean) => {
        if (success) {
            setAlertMessage(`Successfully ${action}d article`);
            setAlertSeverity('success');
        } else if (error) {
            setAlertMessage(`Failed to ${action} article`);
            setAlertSeverity('error');
        }
        setAlertOpen(true);
    };

    useEffect(() => {
        handleAlert('save', saveResult.isSuccess, saveResult.isError);
    }, [saveResult.isSuccess, saveResult.isError]);

    useEffect(() => {
        handleAlert('update', updateResult.isSuccess, updateResult.isError);
    }, [updateResult.isSuccess, updateResult.isError]);

    useEffect(() => {
        handleAlert('delete', deleteResult.isSuccess, deleteResult.isError);
    }, [deleteResult.isSuccess, deleteResult.isError]);

    return (
        <div className="text-editor-container">
            <header className="text-editor-header">{ editing ? "Edit Newsletter" : "Create Newsletter"}</header>
            <BaseAlertDialog
                alertOpen={alertOpen && (updateResult.isError || saveResult.isError || deleteResult.isError || updateResult.isSuccess || saveResult.isSuccess || deleteResult.isSuccess)}
                setAlertOpen={() => {setAlertOpen(false)}}
                alertMessage={alertMessage}
                alertSeverity={alertSeverity}
            />
            <TextField label="title" name="title" value={ formValues.title } onChange={(e) => handleFormChange(e)} />
            <TextField label="author" name="author" value={ formValues.author } onChange={(e) => handleFormChange(e)} />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker format='DD/MM/YYYY' label='date' name="date" value={ dayjs(formValues.date) } onChange={
                (e) => {
                    handleFormChange({target: {name: "date", value: e}});
                }
            } />
            </LocalizationProvider>

            <LoadingButton loading={result.isLoading || saveResult.isLoading || updateResult.isLoading} variant="contained" onClick={handleFormSubmit} >{ editing ? "Save Article" : "Publish Article"}</LoadingButton>
            {editing && <LoadingButton loading={deleteResult.isLoading} variant="contained" onClick={() => deleteArticleTrigger({id: params.id, token: token})} color="error">Delete Article</LoadingButton>}

            <Typography textAlign='left'>Article</Typography>
            <Editor
                editorState={editorState}
                onEditorStateChange={(newState) => {
                    setEditorState(newState);
                    const value = draftToHtml(convertToRaw(newState.getCurrentContent()));
                    handleFormChange({target: {name: "content", value: value }});
                }}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                toolbar={{fontFamily: {options: ['Nunito', 'Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana', 'Chomsky']}}}
            />

            <div className="code-view">
                <p> Preview </p>
                <Card sx={{ width: 4/5, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                    <CardContent style={{ position: 'relative', flexGrow: '1', display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                            <Box sx={{
                                    flexGrow: 1,
                                    overflow: 'auto',
                                }}>
                                <SanitizedHTML htmlContent={formValues.content} />
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </div>

        </div>
    );
};

export default MyEditor;
