import { rovisenApi } from "../../app/api/apiBase"

export const landingApi = rovisenApi.injectEndpoints ({
    endpoints: (builder) => ({
        getTeamMembers: builder.query<any[], any>({
            query: () => ({
                url: '/landing/members',
            })
        }),
        getArticles: builder.query<any[],any>({
            query: () => ({
                url: '/landing/articles',
            })
        }),
        addSubscriber: builder.query<any, { name: string, email: string }>({
            query: ({ name, email }) => ({
                url: '/landing/add_subscriber',
                method: 'POST',
                body: {
                    name: name,
                    email: email,
                }
            })
        })
    })
})

export const {
    useGetTeamMembersQuery,
    useGetArticlesQuery,
    useLazyAddSubscriberQuery
} = landingApi;
