import { Route } from "react-router-dom";
import CreateNewsLetter from "./views/CreateNewsLetter";
import AdminView from "./views/AdminView";

const AdminRoutes = [
    <Route path="/admin" key="admin" element={<AdminView />} />,
    <Route path="/admin/edit-newsletter/:id" key="admin-newsletter-edit" element={<CreateNewsLetter />} />,
    <Route path='/admin/create-newsletter' key="admin-newsletter-create" element={<CreateNewsLetter />} />
]

export default AdminRoutes;
