import { Button } from "@mui/material";
import { useGetNewslettersQuery } from "../../newsLetter/api/newsLetterApi";
import ArticleList from "../../newsLetter/components/ArticleList";
import { useNavigate } from "react-router-dom";

function AdminView() {
    useGetNewslettersQuery({});
    const navigate = useNavigate();
    return (
        <>
            <Button variant="contained" sx={{width: 4/5}} onClick={() => navigate('/admin/create-newsletter') }>Create Article</Button>
            <ArticleList admin={true} />
        </>
    );
}

export default AdminView;
