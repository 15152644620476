import { Box, List } from "@mui/material";
import { grey } from "@mui/material/colors";
import ArticleCard from "./ArticleCard";
import { useSelector } from "react-redux";
import { RootState } from "../../app/state/store";
import { selectArticles } from "../state/newsLetterSlice";
import { formatDate } from "../utils/utils";

function ArticleList({admin=false}: {admin?: boolean}) {
    const news = useSelector((state: RootState) => selectArticles(state))

    return (
            <Box
                className={'upper box'}
                padding={2}
                flexGrow={1}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: {
                        xs: '100%',
                        md: 4/5
                    }
                }}
            >
                <List
                    sx={{
                        bgcolor: 'invisible',
                        flexGrow: 1,
                        overflow: 'auto',
                        height: 0,
                    }}
                    component="nav"
                    subheader={
                        <Box
                            component="div"
                            id="nested-list-subheader"
                            bgcolor={grey[200]}
                            sx={{position: 'sticky', top: 0, zIndex: 1}}
                        >
                            { admin ? "Edit Newsletter" : "Recientes" }
                        </Box>
                    }
                >
                    {news.map((article) => (
                        <Box key={article.id} sx={{ margin: 1 }}>
                            <ArticleCard
                                admin={admin}
                                id={article.id}
                                title={article.title}
                                content={article.content}
                                author={article.author}
                                date={formatDate(article.date)}
                            />
                        </Box>
                    ))}
                </List>
            </Box>
    );
}

export default ArticleList;
