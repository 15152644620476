import OldPage from "./OldPage"
import MapSection from "../components/MapSection";
import { Box, createTheme, ThemeProvider } from "@mui/material";
import TeamMembers from "../components/TeamMembers";
import NewFooter from "../components/NewFooter";

const theme = createTheme({
    typography: {
        fontFamily: 'Nunito',
        button: {
            fontFamily: [
                "Varela Round",
                "-apple-system",
                "BlinkMacSystemFont",
                "Segoe UI",
                "Roboto",
                "Helvetica Neue",
                "Arial",
                "sans-serif",
                "Apple Color Emoji",
                "Segoe UI Emoji",
                "Segoe UI Symbol",
                "Noto Color Emoji"
            ].join(","),
            fontSize: '80%',
            letterSpacing: '0.15rem',
            boxShadow: '0 0.1875rem 0.1875rem 0 rgba(0, 0, 0, 0.1) !important',
            padding: '1.25rem 2rem'
        }
    },
    palette: {
        primary: {
            main: "#473066",
        },
        secondary: {
            main: "#7464a1",
        },
        action: {
            disabledBackground: '#7464a1',
            disabled: '#222222'
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '0.8rem'
                }
            }
        }
    }
})

const LandingPage = () => {
    return <ThemeProvider theme={theme}>
        <Box sx={{ height: '100vh' }}>
            <OldPage />
            <MapSection />
            <TeamMembers />
            <NewFooter />
        </Box>
    </ThemeProvider>
}

export default LandingPage