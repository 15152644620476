function loginApi(username: string, password: string) {
    return fetch(
        `${process.env.REACT_APP_API_URL}/auth/token`,
        {
            method: 'POST',
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: JSON.stringify(`grant_type=&username=${username}&password=${password}&scope=&client_id=&client_secret=`)
        }
    ).then((response) => response.json())
    .then((data) => data)
    .catch((error) => {console.error(error);
    });
}

export { loginApi };
