import { Alert, Collapse, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

function BaseAlertDialog({alertOpen, setAlertOpen, alertMessage, alertSeverity}: any) {
    return (
        <Collapse in={alertOpen}>
            <Alert action={
                <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        setAlertOpen(false);
                    }}
                >
                    <CloseIcon fontSize="inherit" />
                </IconButton>
                }
                severity={alertSeverity}>{alertMessage}</Alert>
        </Collapse>
    );
}

function AlertDialog({errorAlertOpen, setErrorAlertOpen, message}: any) {
    return (
        <Collapse in={errorAlertOpen}>
            <Alert action={
                <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        setErrorAlertOpen(false);
                    }}
                >
                    <CloseIcon fontSize="inherit" />
                </IconButton>
                }
                severity="error">{message}</Alert>
        </Collapse>
    );
}

export { BaseAlertDialog };
export default AlertDialog;
