import { rovisenApi } from "../../app/api/apiBase";

export const newsLetterApi = rovisenApi.injectEndpoints({
    endpoints: (builder) => ({
        getNewsletters: builder.query<any[],any>({
            query: () => ({
                url: '/landing/newsletter',
            })
        }),
        getArticleById: builder.query<any, any>({
            query: (id) => ({
                url: `/landing/newsletter/${id}`,
            })
        }),
        updateArticle: builder.mutation<any, any>({
            query: ({id, title, content, author, date, is_published, token}) => ({
                url: `/landing/newsletter/${id}`,
                method: 'PUT',
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: {
                    'title': title,
                    'content': content,
                    'author': author,
                    'date': date,
                    'is_published': is_published,
                }
            })
        }),
        saveNewsLetter: builder.mutation<any, any>({
            query: ({title, content, author, date, is_published, token}) => ({
                url: '/landing/newsletter/save',
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: {
                    'title': title,
                    'content': content,
                    'author': author,
                    'date': date,
                    'is_published': is_published,
                }
            })
        }),
        deleteNewsLetter: builder.mutation<any, any>({
            query: ({id, token}) => ({
                url: `/landing/newsletter/${id}`,
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                method: 'DELETE',
            })
        })
    }),
});

export const {
    useGetNewslettersQuery,
    useLazyGetArticleByIdQuery,
    useUpdateArticleMutation,
    useSaveNewsLetterMutation,
    useDeleteNewsLetterMutation
} = newsLetterApi;
