import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LandingPage from '../landingPage/views/LandingPage';
import NewsLetterRoutes from '../newsLetter/routes';
import AdminRoutes from '../admin/routes';
import PrivateRoutes from '../authentication/components/PrivateRoutes';
import Login from '../authentication/views/Login';

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" key="landing" element={<LandingPage />} />
        {NewsLetterRoutes}
        <Route key="private" element={<PrivateRoutes />}>
          {AdminRoutes}
        </Route>
        <Route path="/login" key="login" element={<Login />} />
        <Route path="*" key="not found" element={<h1>Page Not Found</h1>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
